export type DomainMapper = {
  algoliaIndex: string,
  algoliaIndexAutocomplete: string,
  algoliaIndexPages: string,
  applicationId: string,
  canonicalUrl?: string,
  countryCode: string,
  currency: string,
  domain: string,
  environment: string,
  /**
   * base name of file, example "vardvaskan".
   * Logo should have 2 variants vardvaskan.svg (black) and vardvaskan-inv.svg (white) in public/logos/
   */
  flagUrl: string,
  /**
   * Primary site in a language, default (not set) is false
   * Eiter b2b or b2c, not both
   * for hreflang links
   * @important - this will match first found in language
   * so DON'T have this pointing to a dev/stage site in production
   */
  languagePrimary?: boolean,
  locale: string, // used to load correct file in /lang/{locale}.json
  logoUrl:string,
  noRobots?: boolean, //  false if not set
  productBasePath: string,
  sitemapUrl: string,
  storyblokBasePath: string,
  url: string,
  /**
   * If the host name has languages in path, example /se /no
   *
   */
  subFolder: boolean;
  keywordSuffix?: string;
}

const domainMap = [
  // LOCAL
  {
    countryCode: 'no',
    currency: 'NOK',
    domain: 'localhost:3011',
    environment: 'local',
    flagUrl: '/flags/1x1/no.svg',
    languagePrimary: true,
    locale: 'no',
    logoUrl: 'bywe',
    noRobots: true,
    productBasePath: 'produkt',
    sitemapUrl: 'https://www.example.com/se-sitemap.xml',
    storyblokBasePath: 'no',
    url: 'https://localhost:3011/no',
    algoliaIndex: '89fce283-7c9a-49f5-b3db-9f492daf4daf-nb-NO-products',
    algoliaIndexAutocomplete: '89fce283-7c9a-49f5-b3db-9f492daf4daf-nb-NO-products_query_suggestions',
    algoliaIndexPages: 'content_no',
    applicationId: '671',
    subFolder: true,
    keywordSuffix: '--no',
  },
  {
    countryCode: 'se',
    currency: 'SEK',
    domain: 'localhost:3011',
    environment: 'local',
    flagUrl: '/flags/1x1/se.svg',
    languagePrimary: true,
    locale: 'sv',
    logoUrl: 'bywe',
    noRobots: true,
    productBasePath: 'produkt',
    sitemapUrl: 'https://www.example.com/se-sitemap.xml',
    storyblokBasePath: 'se',
    url: 'https://localhost:3011/se',
    algoliaIndex: 'dbfe5f99-b376-4288-87cb-e660d19ebb71-sv-SE-products',
    algoliaIndexAutocomplete: 'dbfe5f99-b376-4288-87cb-e660d19ebb71-sv-SE-products-products_query_suggestions',
    algoliaIndexPages: 'content_se',
    applicationId: '701',
    subFolder: true,
    keywordSuffix: '--se',
  },
  {
    countryCode: 'dk',
    currency: 'DKK',
    domain: 'localhost:3011',
    environment: 'local',
    flagUrl: '/flags/1x1/se.svg',
    languagePrimary: true,
    locale: 'da',
    logoUrl: 'bywe',
    noRobots: true,
    productBasePath: 'produkt',
    sitemapUrl: 'https://www.example.com/se-sitemap.xml',
    storyblokBasePath: 'dk',
    url: 'https://localhost:3011/dk',
    algoliaIndex: 'f31e950c-a4cb-49aa-8f37-993e6dcce9fa-da-DK-products',
    algoliaIndexAutocomplete: 'f31e950c-a4cb-49aa-8f37-993e6dcce9fa-da-DK-products_query_suggestions',
    algoliaIndexPages: 'content_dk',
    applicationId: '703',
    subFolder: true,
    keywordSuffix: '--dk',
    allowBackorders: true,
  },

  // FEATURE STAGE
  {
    domain: 'featurestaging.bywe.com',
    storyblokBasePath: 'no',
    locale: 'no',
    productBasePath: 'produkt',
    environment: 'staging',
    flagUrl: '/flags/1x1/no.svg',
    logoUrl: 'bywe',
    countryCode: 'no',
    url: 'https://featurestaging.bywe.com/no',
    currency: 'NOK',
    sitemapUrl: 'https://www.example.com/se-sitemap.xml',
    noRobots: true,
    languagePrimary: true,
    algoliaIndex: '89fce283-7c9a-49f5-b3db-9f492daf4daf-nb-NO-products',
    algoliaIndexAutocomplete: '89fce283-7c9a-49f5-b3db-9f492daf4daf-nb-NO-products_query_suggestions',
    algoliaIndexPages: 'content_no',
    applicationId: '671',
    subFolder: true,
    keywordSuffix: '--no',
  },

  // STAGE
  {
    domain: 'staging.bywe.com',
    storyblokBasePath: 'no',
    locale: 'no',
    productBasePath: 'produkt',
    environment: 'staging',
    flagUrl: '/flags/1x1/no.svg',
    logoUrl: 'bywe',
    countryCode: 'no',
    url: 'https://staging.bywe.com/no',
    currency: 'NOK',
    sitemapUrl: 'https://www.example.com/se-sitemap.xml',
    noRobots: true,
    languagePrimary: true,
    algoliaIndex: '89fce283-7c9a-49f5-b3db-9f492daf4daf-nb-NO-products',
    algoliaIndexAutocomplete: '89fce283-7c9a-49f5-b3db-9f492daf4daf-nb-NO-products_query_suggestions',
    algoliaIndexPages: 'content_no',
    applicationId: '671',
    subFolder: true,
    keywordSuffix: '--no',
  },
  {
    domain: 'staging.bywe.com',
    storyblokBasePath: 'se',
    locale: 'sv',
    productBasePath: 'produkt',
    environment: 'staging',
    flagUrl: '/flags/1x1/no.svg',
    logoUrl: 'bywe',
    countryCode: 'se',
    url: 'https://staging.bywe.com/se',
    currency: 'SEK',
    sitemapUrl: 'https://www.example.com/se-sitemap.xml',
    noRobots: true,
    languagePrimary: true,
    algoliaIndex: '86b4bb03-f349-44bd-906c-b716acc74233-sv-SE-products',
    algoliaIndexAutocomplete: '86b4bb03-f349-44bd-906c-b716acc74233-sv-SE-products_query_suggestions',
    algoliaIndexPages: 'content_se',
    applicationId: '701',
    subFolder: true,
    keywordSuffix: '--se',
  },
  {
    domain: 'staging.bywe.com',
    storyblokBasePath: 'dk',
    locale: 'da',
    productBasePath: 'produkt',
    environment: 'staging',
    flagUrl: '/flags/1x1/no.svg',
    logoUrl: 'bywe',
    countryCode: 'dk',
    url: 'https://staging.bywe.com/dk',
    currency: 'SEK',
    sitemapUrl: 'https://www.example.com/se-sitemap.xml',
    noRobots: true,
    languagePrimary: true,
    algoliaIndex: '86b4bb03-f349-44bd-906c-b716acc74233-sv-SE-products',
    algoliaIndexAutocomplete: '86b4bb03-f349-44bd-906c-b716acc74233-sv-SE-products_query_suggestions',
    algoliaIndexPages: 'content_dk',
    applicationId: '703',
    subFolder: true,
    keywordSuffix: '--dk',
  },
  // PREVIEW
  {
    domain: 'preview.bywe.com',
    storyblokBasePath: 'no',
    locale: 'no',
    productBasePath: 'produkt',
    environment: 'preview',
    flagUrl: '/flags/1x1/no.svg',
    logoUrl: 'bywe',
    countryCode: 'no',
    url: 'https://preview.bywe.com/no',
    currency: 'NOK',
    sitemapUrl: 'https://www.example.com/se-sitemap.xml',
    noRobots: true,
    languagePrimary: true,
    algoliaIndex: '9975c645-989c-433b-a2f0-4d9f88d99df3-nb-NO-products',
    algoliaIndexAutocomplete: '9975c645-989c-433b-a2f0-4d9f88d99df3-nb-NO-products_query_suggestions',
    algoliaIndexPages: 'content_no',
    applicationId: '671',
    subFolder: true,
    keywordSuffix: '--no',
  },
  {
    domain: 'preview.bywe.com',
    storyblokBasePath: 'se',
    locale: 'sv',
    productBasePath: 'produkt',
    environment: 'preview',
    flagUrl: '/flags/1x1/no.svg',
    logoUrl: 'bywe',
    countryCode: 'se',
    url: 'https://preview.bywe.com/se',
    currency: 'SEK',
    sitemapUrl: 'https://www.example.com/se-sitemap.xml',
    noRobots: true,
    languagePrimary: true,
    algoliaIndex: 'dbfe5f99-b376-4288-87cb-e660d19ebb71-sv-SE-products',
    algoliaIndexAutocomplete: 'dbfe5f99-b376-4288-87cb-e660d19ebb71-sv-SE-products-products_query_suggestions',
    algoliaIndexPages: 'content_se',
    applicationId: '701',
    subFolder: true,
    keywordSuffix: '--se',
    allowBackorders: false,
  },
  {
    domain: 'preview.bywe.com',
    storyblokBasePath: 'dk',
    locale: 'da',
    productBasePath: 'produkt',
    environment: 'preview',
    flagUrl: '/flags/1x1/no.svg',
    logoUrl: 'bywe',
    countryCode: 'dk',
    url: 'https://preview.bywe.com/dk',
    currency: 'DKK',
    sitemapUrl: 'https://www.example.com/se-sitemap.xml',
    noRobots: true,
    languagePrimary: true,
    algoliaIndex: '86b4bb03-f349-44bd-906c-b716acc74233-sv-SE-products-products',
    algoliaIndexAutocomplete: '86b4bb03-f349-44bd-906c-b716acc74233-sv-SE-products-products_query_suggestions',
    algoliaIndexPages: 'content_dk',
    applicationId: '703',
    subFolder: true,
    keywordSuffix: '--dk',
    allowBackorders: false,
  },

  // PREPROD
  {
    domain: 'preprod.bywe.com',
    storyblokBasePath: 'no',
    locale: 'no',
    productBasePath: 'produkt',
    environment: 'preprod',
    flagUrl: '/flags/1x1/no.svg',
    logoUrl: 'bywe',
    countryCode: 'no',
    url: 'https://preprod.bywe.com/no',
    currency: 'NOK',
    sitemapUrl: 'https://ecom-api.bywe.com/storage/sitemaps/sitemap-no.xml',
    noRobots: true,
    languagePrimary: true,
    algoliaIndex: 'f31e950c-a4cb-49aa-8f37-993e6dcce9fa-da-DK-products',
    algoliaIndexAutocomplete: 'f31e950c-a4cb-49aa-8f37-993e6dcce9fa-da-DK-products_query_suggestions',
    algoliaIndexPages: 'content_no',
    applicationId: '703',
    subFolder: true,
    keywordSuffix: '--no',
    allowBackorders: true,
  },
  {
    domain: 'preprod.bywe.com',
    storyblokBasePath: 'se',
    locale: 'sv',
    productBasePath: 'produkt',
    environment: 'preprod',
    flagUrl: '/flags/1x1/no.svg',
    logoUrl: 'bywe',
    countryCode: 'se',
    url: 'https://preprod.bywe.com/se',
    currency: 'SEK',
    sitemapUrl: 'https://ecom-api.bywe.com/storage/sitemaps/sitemap-se.xml',
    noRobots: true,
    languagePrimary: true,
    algoliaIndex: 'dbfe5f99-b376-4288-87cb-e660d19ebb71-sv-SE-products',
    algoliaIndexAutocomplete: 'dbfe5f99-b376-4288-87cb-e660d19ebb71-sv-SE-products-products_query_suggestions',
    algoliaIndexPages: 'content_se',
    applicationId: '701',
    subFolder: true,
    keywordSuffix: '--se',
    allowBackorders: false,
  },
  {
    domain: 'preprod.bywe.com',
    storyblokBasePath: 'dk',
    locale: 'da',
    productBasePath: 'produkt',
    environment: 'preprod',
    flagUrl: '/flags/1x1/no.svg',
    logoUrl: 'bywe',
    countryCode: 'dk',
    url: 'https://preprod.bywe.com/dk',
    currency: 'DKK',
    sitemapUrl: 'https://ecom-api.bywe.com/storage/sitemaps/sitemap-dk.xml',
    noRobots: true,
    languagePrimary: true,
    algoliaIndex: 'f31e950c-a4cb-49aa-8f37-993e6dcce9fa-da-DK-products',
    algoliaIndexAutocomplete: 'f31e950c-a4cb-49aa-8f37-993e6dcce9fa-da-DK-products_query_suggestions',
    algoliaIndexPages: 'content_dk',
    applicationId: '703',
    subFolder: true,
    keywordSuffix: '--dk',
    allowBackorders: false,
  },

  // PRODUCTION
  {
    domain: 'bywe.com',
    storyblokBasePath: 'no',
    locale: 'no',
    productBasePath: 'produkt',
    environment: 'production',
    flagUrl: '/flags/1x1/no.svg',
    logoUrl: 'bywe',
    countryCode: 'no',
    url: 'https://bywe.com/no',
    currency: 'NOK',
    sitemapUrl: 'https://ecom-api.bywe.com/storage/sitemaps/sitemap-no.xml',
    noRobots: false,
    languagePrimary: true,
    algoliaIndex: '9975c645-989c-433b-a2f0-4d9f88d99df3-nb-NO-products',
    algoliaIndexAutocomplete: '9975c645-989c-433b-a2f0-4d9f88d99df3-nb-NO-products_query_suggestions',
    algoliaIndexPages: 'content_no',
    applicationId: '671',
    subFolder: true,
    keywordSuffix: '--no',
    allowBackorders: true,
  },
  {
    domain: 'bywe.com',
    storyblokBasePath: 'se',
    locale: 'sv',
    productBasePath: 'produkt',
    environment: 'production',
    flagUrl: '/flags/1x1/no.svg',
    logoUrl: 'bywe',
    countryCode: 'se',
    url: 'https://bywe.com/se',
    currency: 'SEK',
    sitemapUrl: 'https://ecom-api.bywe.com/storage/sitemaps/sitemap-se.xml',
    noRobots: false,
    languagePrimary: true,
    algoliaIndex: 'dbfe5f99-b376-4288-87cb-e660d19ebb71-sv-SE-products',
    algoliaIndexAutocomplete: 'dbfe5f99-b376-4288-87cb-e660d19ebb71-sv-SE-products-products_query_suggestions',
    algoliaIndexPages: 'content_se',
    applicationId: '701',
    subFolder: true,
    keywordSuffix: '--se',
    allowBackorders: false,
  },
  // {
  //   domain: 'bywe.com',
  //   storyblokBasePath: 'dk',
  //   locale: 'da',
  //   productBasePath: 'produkt',
  //   environment: 'production',
  //   flagUrl: '/flags/1x1/no.svg',
  //   logoUrl: 'bywe',
  //   countryCode: 'dk',
  //   url: 'https://bywe.com/dk',
  //   currency: 'DKK',
  //   sitemapUrl: 'https://ecom-api.bywe.com/storage/sitemaps/sitemap-dk.xml',
  //   noRobots: false,
  //   languagePrimary: true,
  //   algoliaIndex: 'f31e950c-a4cb-49aa-8f37-993e6dcce9fa-da-DK-products',
  //   algoliaIndexAutocomplete: 'f31e950c-a4cb-49aa-8f37-993e6dcce9fa-da-DK-products_query_suggestions',
  //   algoliaIndexPages: 'content_dk',
  //   applicationId: '703',
  //   subFolder: true,
  // },
] as DomainMapper[];

export const findDomainByHostAndPath = (hostname: string|undefined, path: string|undefined): DomainMapper | null => {
  let foundDomain = null as null | DomainMapper;
  const foundDomains = domainMap.filter((d: { domain: string; subFolder: boolean; }) => d.domain === hostname);
  if (foundDomains.length > 1) {
    const nonSubFolderDomain = foundDomains.find((d: { subFolder: boolean; }) => !d.subFolder);
    if (nonSubFolderDomain) {
      foundDomain = nonSubFolderDomain;
    } else {
      path = path?.split('/').filter((str) => str.length).shift();
      const foundPathDomainMap = foundDomains.find((d: { storyblokBasePath: string | undefined; }) => d.storyblokBasePath === path) || null;
      if (foundPathDomainMap) {
        foundDomain = foundPathDomainMap;
      } else {
        foundDomain = foundDomains[0];
      }
    }

  } else {
    foundDomain = foundDomains[0] ?? domainMap[0] ?? null;
  }
  return foundDomain;
};

/**
 * Fallback strategies
 * If host name is matched but not language, ex /fi but we only have /se /no,
 * it will fall back to the first entry, so place fallback language first
 * We need a fallback language to display 404 page
 */

export default domainMap as DomainMapper[];
