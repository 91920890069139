import { defineStore } from 'pinia';
import {
  UserProfile,
  UserCommunicationPreferences,
  UserNotification,
  UserProfileUpdatePayload,
  emptyUser,
  OrderProposal,
  ProductBackorderNotice
} from '~/constants/types/norce';
import useApiFetch from '~/composeables/useApiFetch';
import useAlgoliaFetch from '~/composeables/useAlgoliaFetch';
import Product, { ProductModel } from '~/models/product';
import { useUiStore } from '~/store/ui';
import { PriceInfo, ProductPrice, ProductPriceApiResponse } from '~/constants/types/algolia';
import { ProductVariantModel } from '~/models/productVariant';
import { useCartStore } from '~/store/cart';
import { NorceUserTypes } from '~/constants/norceCodes';
import useLinkReplacer from '~/composeables/useLinkReplacer';
import * as Sentry from '@sentry/vue';
import { useGlobalContentStore } from '~/store/globalContent';

export type UserPrice = {
  status: 'logginIn' | 'notLoggedIn' | 'pending' | 'ok' | 'error';
  crossSell?: boolean;
  price?: PriceInfo;
};

export type FavoriteProductList = {
  product: ProductModel;
  quantity: number;
  selectedVariant: ProductVariantModel
}

export const useUserStore = defineStore('userStore', {
  state: () => ({
    userProfile: { ...emptyUser } as UserProfile,
    loggedIn: false as boolean,
    loadedLogin: false,
    accountToken: '',
    ordersCount: -1,
    favoriteProductList: [] as FavoriteProductList[],
    favoritesLoaded: false as boolean,
    communicationPreferences: {} as UserCommunicationPreferences,
    notifications: [] as UserNotification[],
    userPrice: [] as ProductPrice[],
    crossSellUserPrice: [] as ProductPrice[],
    loadPriceQueue: [] as string[],
    loadPriceTimer: null as any,
    backorderNotices: [] as ProductBackorderNotice[],
  }),
  actions: {
    /**
     * loads user prices from api
     * @todo - max is 1000 at a time
     */
    async loadUserPrices(crossSell: boolean = false) {
      if (this.loadPriceQueue.length > 0) {
        this.loadPriceTimer = null;
        const thisLoadQueue = JSON.parse(JSON.stringify(this.loadPriceQueue));
        const { apiPost, handleApiError } = useApiFetch();

        const res = await apiPost<ProductPriceApiResponse>(
          '/products/prices',
          { partNos: this.loadPriceQueue, crossSell }
        );
        this.loadPriceQueue = [];
        if (!res) {
          handleApiError();
        } else {
          if (crossSell) {
            this.crossSellUserPrice = this.crossSellUserPrice.concat(res.prices);
          } else {
            this.userPrice = this.userPrice.concat(res.prices);
          }

          /**
           * This checks if we actually got the products we wanted
           * If not, it inserts as null ie "error", otherwise, it gets stuck in an endless loop
           */
          thisLoadQueue.forEach((f: string)=> {
            const find = this.userPrice.findIndex((i)=> i.partNo === f);
            if (find === -1) {
              console.warn(`No api response price for part no: ${f}`);
              this.userPrice.push({
                partNo: f,
                priceInfo: null,
              });
            }
          });
        }
      }
    },
    async loadUserData() {
      const { apiGet, accountToken } = useApiFetch();
      if (accountToken.value) {
        const getUser = await apiGet<UserProfile>(
          '/users/me'
        );

        if (getUser) {
          this.setUserProfile(getUser);

          if (useGlobalContentStore().getAllowBackorders) {
            await this.loadBackorderProducts();
          }
          return true;
        } else {
          accountToken.value = null;
          await this.clearUserProfile();
          return false;
        }

      } else {
        this.loadedLogin = true;
        return false;
      }
    },
    setUserProfile(payload: UserProfile) {
      this.userProfile = payload;
      this.loggedIn = true;
      this.loadedLogin = true;

      if (payload.savedBasketId) {
        useCartStore().setCartFromId(payload.savedBasketId);
      }

      Sentry.setUser({ id: payload.id, email: payload.email });
    },
    async loadBackorderProducts() {
      const { apiGet } = useApiFetch();
      const res = await apiGet<ProductBackorderNotice[]>('/users/me/backorder/products');
      if (res) {
        this.backorderNotices = res;
      }
    },
    async updateUser(payload: UserProfileUpdatePayload) {
      const { apiPut } = useApiFetch();
      const res = await apiPut<UserProfile>(
        '/users/me',
        payload
      );

      this.userProfile = res;
    },
    async logIn({ email, password, toAccountPage, noReload }: { email: string; password: string; toAccountPage: boolean; noReload?: boolean }) {
      const { apiPost, accountToken } = useApiFetch();
      const uiStore = useUiStore();
      const getToken = await apiPost<string>(
        '/users/login',
        {
          email: email,
          password: password,
        }
      );
      if (getToken && typeof getToken === 'string') {
        accountToken.value = getToken;
        setTimeout(async() => {
          await this.loadUserData();

          if (this.userProfile.currentlyEditingOrderProposal) {
            await useCartStore().setOrderProposalAsCart(this.userProfile.currentlyEditingOrderProposal);
          }

          if (this.userProfile?.role?.type === NorceUserTypes.Admin || this.userProfile?.role?.type === NorceUserTypes.User) {
            await this.loadNotifications();
          }

          if (toAccountPage) {
            const accountLandingUrl = this.userProfile.role?.type === NorceUserTypes.Sales ? '/salestool/dashboard' : '/account/dashboard';
            const { manualUrl } = useLinkReplacer();
            await useRouter().push(manualUrl(accountLandingUrl));
          }
          uiStore.closeAllModal();
        }, 200);

        return true;
      }
      return false;
    },
    async logOut(toFrontpage = true) {
      const { apiPost, accountToken } = useApiFetch();
      await apiPost<string>('/users/logout');
      this.loadedLogin = false;
      setTimeout(async() => {
        this.clearUserProfile();
        accountToken.value = null;
        Sentry.setUser(null);

        if (toFrontpage) {
          const { manualUrl } = useLinkReplacer();
          // I don't know why but both useRouter().replace and navigateTo() triggers errors
          window.location.href = manualUrl('/');
        }
      }, 200);
    },
    clearUserProfile() {
      this.userProfile = { ...emptyUser };
      this.loggedIn = false;
      this.loadedLogin = false;
      this.favoritesLoaded = false;
      this.favoriteProductList = [];
      this.userPrice = [];
      this.backorderNotices = [];
      useCartStore().deleteCart();
    },
    async saveFavoriteProduct(partNo: string, quantity = 1) {
      const { apiPost } = useApiFetch();
      if (partNo && this.userProfile) {
        await apiPost<any>(
          '/users/me/favorites/products',
          {
            partNo,
            quantity,
          }
        );

        await this.loadUserData();
        await this.loadFavoriteProducts();
      }
      return true;
    },
    async updateFavoriteProductQuantity(partNo: string, quantity: number) {
      const { apiPut } = useApiFetch();
      if (partNo && this.userProfile) {
        await apiPut<any>(
          '/users/me/favorites/products',
          {
            partNo,
            quantity,
          }
        );

        await this.loadUserData();
        await this.loadFavoriteProducts();
      }
      return true;
    },
    async clearFavoriteProducts() {
      const { apiDelete } = useApiFetch();
      const partNo = this.userProfile.favoriteProducts?.map(favorite => favorite.id) ?? [];
      if (partNo.length) {
        await apiDelete(
          '/users/me/favorites/products',
          {
            partNo,
          }
        );

        await this.loadUserData();

        this.favoriteProductList = [];
      }
    },
    async loadFavoriteProducts() {
      const { getExactVariantByPartNo } = useAlgoliaFetch();
      const partNos = Object.values(this.userProfile.favoriteProducts).map(favorite => favorite.id) ?? [];
      if (partNos.length > 0) {
        const res = await getExactVariantByPartNo(partNos);
        if (res) {
          this.favoriteProductList = res.hits.map((m) => {
            const product =  Product.create([ m ], true);
            const productQuantity = this.userProfile.favoriteProducts.find((f)=> f.id === m.partNo)?.quantity ?? 1;
            return {
              product,
              quantity: productQuantity,
              selectedVariant: product.variants[0],
            };
          });
        }
      } else {
        this.favoriteProductList = [];
      }

      this.favoritesLoaded = true;
    },
    async loadNotifications() {
      if (this.isCustomerSuccessUser || this.isSalesRepUser || !this.loggedIn) {
        return;
      }
      const { apiGet } = useApiFetch();
      const getUserNotifications = await apiGet<UserNotification[]>(
        '/users/me/notifications',
        null,
        !this.userProfile.impersonatingCompany
      );
      if (getUserNotifications) {
        this.notifications = getUserNotifications;
      }
    },
    removeNotification(id: string) {
      this.notifications = this.notifications.filter((notification) => notification.id !== id);
    },
    removeNotifications() {
      this.notifications = [];
    },
    async markNotificationAsSeen(id: number|string) {
      const { apiPost } = useApiFetch();
      await apiPost('/order-proposals/seen', {
        basketId: id,
      });
      this.loadNotifications();
    },
    markAllNotificationAsSeen(type: string | null = null) {
      const { apiPost } = useApiFetch();
      const unseen = this.getUnseenNotifications(type) as UserNotification[];
      // We don't actually wait for replys, just mark them as read
      unseen.forEach((u) => {
        apiPost('/order-proposals/seen', {
          basketId: u.id,
        }, {}, false);
        const index = this.notifications.findIndex(f=> {
          if (type) {
            return f.id === u.id && f.type === type;
          }
          return f.id === u.id;
        });
        if (index > -1) {
          this.notifications[index].seen = true;
        }
      });
    },
    async loadCommunicationPreferences() {
      const { apiGet } = useApiFetch();
      const res = await apiGet<UserCommunicationPreferences>(
        '/users/me/communications',
        {},
        false
      );

      this.communicationPreferences = {
        newsletter: res.newsletter ?? false,
        backorderStockAlerts: res.backorderStockAlerts ?? false,
      };
    },
    async updateCommunicationPreferences(subscribe: boolean, backorderStockAlerts: boolean) {
      const { apiPut, handleApiError } = useApiFetch();
      const uiStore = useUiStore();
      const { $t } = useNuxtApp();

      const res = await apiPut<UserCommunicationPreferences>(
        '/users/me/communications',
        {
          newsletter: subscribe,
          backorderStockAlerts: backorderStockAlerts,
        }
      );

      if (res) {
        this.communicationPreferences = res;
        uiStore.setTemporarySuccessMsg($t('mypage.communications.updated'));
      } else {
        handleApiError();
      }
    },
    async addFavoriteBrand(code: string) {
      const { apiPost, handleApiError } = useApiFetch();
      const res = await apiPost(
        '/users/me/favorites/brands',
        { code }
      );
      if (!res) {
        handleApiError();
      } else {
        if (!this.userProfile.favoriteBrands.includes(code)) {
          this.userProfile.favoriteBrands.push(code);
        }
      }
    },
    async removeFavoriteBrand(code: string) {
      const { apiDelete, handleApiError } = useApiFetch();
      const res = await apiDelete(
        '/users/me/favorites/brands',
        { code }
      );
      if (!res) {
        handleApiError();
      } else {
        this.userProfile.favoriteBrands = this.userProfile.favoriteBrands.filter((f)=> f !== code);
      }
    },
    async impersonateCompany(companyId: number|string) {
      const { apiPost, handleApiError } = useApiFetch();
      const res = await apiPost<UserProfile>('/users/salesrep/impersonate', {
        companyId,
      }, null, false);

      if (res) {
        useCartStore().deleteCart();
        this.userProfile = res;
        if (res.savedBasketId) {
          useCartStore().setCartFromId(res.savedBasketId);
        }
        await this.loadNotifications();
        // clears all prices because they might be different
        this.userPrice = [];

        // reset backorder products
        this.backorderNotices = [];
        await this.loadBackorderProducts();
        return true;
      } else {
        handleApiError(undefined, undefined, true);
      }
    },
    async stopImpersonatingCompany() {
      const { apiDelete, handleApiError } = useApiFetch();

      const res = await apiDelete<UserProfile>('/users/salesrep/impersonate');

      if (res) {
        this.userProfile = res;
        await this.loadNotifications();
        this.backorderNotices = [];
        useCartStore().deleteCart();
      } else {
        handleApiError();
      }
    },
    async editOrderProposal(orderProposal: OrderProposal, redirectToCheckout: boolean) {
      let res: boolean|undefined = true;
      if (!this.userProfile.impersonatingCompany || orderProposal.companyId != this.userProfile.company.id) {
        res = await this.impersonateCompany(orderProposal.companyId);
      }
      if (res) {
        const res = await this.setCurrentlyEditingOrderProposal(orderProposal);
        if (res) {
          await useCartStore().setOrderProposalAsCart(orderProposal);

          if (redirectToCheckout) {
            const { manualUrl } = useLinkReplacer();
            navigateTo(manualUrl('/checkout'));
          } else {
            const { $t } = useNuxtApp();
            useUiStore().setTemporarySuccessMsg($t('mypage.orderProposal.cartLoaded', { id: this.userProfile.currentlyEditingOrderProposal?.norceBasketId, name: orderProposal.companyName }), 'success');
          }
        }
      }
    },
    async setHasSeenOnboarding() {
      const { apiPut, handleApiError } = useApiFetch();
      const res = await apiPut<UserProfile>('/users/me/onboarding');

      if (res) {
        this.userProfile = res;
        return true;
      } else {
        handleApiError();
        return false;
      }
    },
    async setCurrentlyEditingOrderProposal(orderProposal?: OrderProposal) {
      const { apiPost, handleApiError } = useApiFetch();
      let url = '/users/me/orderProposals/';
      if (orderProposal) {
        url += orderProposal.id;
      }
      const res = await apiPost<UserProfile>(url);

      if (res) {
        this.userProfile = res;
        return true;
      } else {
        handleApiError();
        return false;
      }
    },
  },
  getters: {
    isLoggedIn(state): boolean {
      return state.loggedIn && state.loadedLogin;
    },
    isSalesRepUser(state): boolean {
      return state.loggedIn && state.userProfile?.role?.type === NorceUserTypes.Sales;
    },
    /**
     * this is extremly confusing but a customer support is called customer success
     */
    isCustomerSuccessUser: (state): boolean => {
      return state.loggedIn && state.userProfile?.role?.type === NorceUserTypes.CustomerSuccess;
    },
    isAdminUser(state) {
      return state.loggedIn && state.userProfile?.role?.type === NorceUserTypes.Admin;
    },
    isFinanceUser(state) {
      return state.loggedIn && state.userProfile?.role?.type === NorceUserTypes.Finance;
    },
    isEditingOrderProposal(state) {
      if (this.isSalesRepUser) {
        return state.loggedIn && state.userProfile.currentlyEditingOrderProposal && state.userProfile.currentlyEditingOrderProposal.basketId === useCartStore().cartId;
      } else {
        return state.loggedIn && state.userProfile.currentlyEditingOrderProposal && state.userProfile.currentlyEditingOrderProposal.customerBasketId === useCartStore().cartId;
      }
    },
    allowPointsPurchase(state) {
      return state.loggedIn && state.userProfile?.allowPointsPurchase;
    },
    hasLoadedLogin(state) {
      return state.loadedLogin;
    },
    getPrice: (state) => (partNo:string, crossSell: boolean = false): UserPrice  => {
      if (!state.loggedIn && !state.loadedLogin) {
        return {
          status: 'logginIn',
        };
      }
      if (!state.loggedIn) {
        return {
          status: 'notLoggedIn',
        };
      }
      const price = crossSell ? state.crossSellUserPrice.find((f)=> f.partNo === partNo) : state.userPrice.find((f)=> f.partNo === partNo);
      if (!price) {
        if (!state.loadPriceQueue.includes(partNo)) {
          state.loadPriceQueue.push(partNo);

          /**
           * If SSR, we only adds to the cue, and default.vue loads onMounted
           * If not, this will fail hydration
           */
          if (!process.server) {
            if (!state.loadPriceTimer) {
              state.loadPriceTimer = setTimeout(()=> {
                const thisStore= useUserStore();
                state.loadPriceTimer = null;
                thisStore.loadUserPrices(crossSell);
              }, 25);
            }
          }
        }
        return {
          status: 'pending',
        };

      }
      if (!price.priceInfo) {
        return { status: 'error', price: undefined };
      }
      return { status: 'ok', price: price.priceInfo };
    },
    isSalePrice: (state) => (partNo:string): boolean  => {
      const price = state.userPrice.find((f)=> f.partNo === partNo);
      if (!price || !price.priceInfo) {
        return false;
      }
      /**
       * This is how we used to calulate it, but let's trust the backend on this
       *   return (userPrice.value.price?.isCustomerPrice || userPrice.value.price?.isCampaignPrice) &&
       *     userPrice.value.price?.priceBeforeDiscountBeforeVat !== '0' &&
       *     $toNumber(userPrice.value.price?.priceBeforeDiscountBeforeVat || '0') > $toNumber(userPrice.value.price?.priceBeforeVat || '0');
       */
      return price.priceInfo.isOnSale || false;
    },
    getNotificationByType: (state) => (type: string): UserNotification[]  => {
      return state.notifications.filter((notification) => notification.type === type);
    },
    getUnseenNotifications: (state) => (type: string | null = null): UserNotification[]  => {
      const list = state.notifications.filter((notification) => !notification.seen);
      return type ? list?.filter((notification) => notification.type === type) : list;
    },
    currentCompanyId(state) {
      return state.userProfile.company.id;
    },
    canFavorizeProducts(state) {
      return this.isLoggedIn && !this.isCustomerSuccessUser;
    },
  },
}
);
